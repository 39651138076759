#upload-file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
input.custom-file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.form-check-input {
  @apply appearance-none bg-no-repeat bg-contain border-0 box-border float-none h-9 mr-0 mb-0 -ml-16 -mt-0.5 align-top w-12;
  font-family: inherit;
  line-height: inherit;
  background-color: rgba(0, 158, 247, 1);
  background-position: right center;
  transition: background-position 0.15s ease-in-out 0s;
  border-radius: 52px;
  border-color: rgba(0, 158, 247, 1);
}

.form-label {
  @apply box-border inline-block font-medium text-[13px] leading-[20px] mb-2 outline-none;
  color: rgba(70, 78, 95, 1);
}

.form-radio-wrapper {
  @apply appearance-none bg-no-repeat bg-contain border-0 box-border cursor-pointer float-none h-7 mr-0 mb-0 -ml-9 -mt-0.5 align-top w-7;
  font-family: inherit;
  line-height: inherit;
  background-color: rgba(239, 242, 245, 1);
  background-position: center center;
  transition: background-color 0.15s ease-in-out 0s,
    background-position 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
    box-shadow 0.15s ease-in-out 0s;
  border-radius: 50%;
}

.form-radio {
  @apply appearance-none bg-no-repeat bg-contain border-0 box-border cursor-pointer float-none h-7 mr-0 mb-0 -ml-9 -mt-0.5 align-top w-7;
  font-family: inherit;
  line-height: inherit;
  background-color: rgba(239, 242, 245, 1);
  background-position: center center;
  transition: background-color 0.15s ease-in-out 0s,
    background-position 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
    box-shadow 0.15s ease-in-out 0s;
  border-radius: 50%;
}

.form-input {
  @apply appearance-none bg-clip-padding rounded-lg block font-medium text-sm m-0 px-6 py-3.5 w-full outline-none min-h-[45px];
  color: rgb(102, 107, 128, 1);
  background-color: rgba(245, 248, 250, 1);
  border-color: rgba(245, 248, 250, 1);
  transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
}

.form-select {
  @apply appearance-none bg-no-repeat rounded-lg border-none block outline-none font-medium text-[15px] leading-normal py-3 pl-[19.5px] pr-12 w-full;
  color: rgb(102, 107, 128, 1);
  background-size: 16px 12px;
  background-color: rgba(245, 248, 250, 1);
  border-color: rgba(245, 248, 250, 1);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%237e8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.multiple-form-select {
  @apply appearance-none bg-no-repeat min-h-[45px] rounded-lg border-none block outline-none font-medium text-[15px] leading-normal py-1 pl-[8px] pr-8 w-full;
  color: rgb(102, 107, 128, 1);
  background-size: 16px 12px;
  background-color: rgba(245, 248, 250, 1);
  border-color: rgba(245, 248, 250, 1);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #f6f9fb;
  font-size: 13px;
  font-weight: 400 !default;
  font-family: Poppins, Helvetica, "sans-serif" !important !default;
}

@layer base {
  html {
    font-family: Poppins;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}
input [type="checkbox"] {
  accent-color: #e85936;
}

input[type="radio"] {
  accent-color: #e85936;
}
table-space {
  border-spacing: 10px 10px;
}

.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: #f1416c;
  padding-left: 0.25rem;
  font-weight: bold;
}

div.profileImage {
  width: 30px;
  border: 2px solid #fff;
  border-radius: 8px;
  height: 30px !important;
  vertical-align: middle !important;
  background: #cd4927;
  text-align: center;
  line-height: 26px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 16px;
}

p.companyname {
  width: 100px;
}

p.companyname > svg {
  display: inline-flex;
}

ul.dropdown-menu {
  left: -202px;
  top: 6px;
  border-radius: 6px;
  max-height: 150px;
  overflow-y: auto;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
  text-decoration: none;
}

.dropdown-submenu > .dropdown-menu {
  position: absolute;
  left: auto;
  right: 100% !important;
  margin-top: -6px;
  border-radius: 6px;
  max-height: 250px;
  width: max-content;
}

.side-navigation {
  color: rgba(0, 0, 0, 0.87);
  background-color: #1a0a06;
}

.poppins-font {
  font-family: Poppins;
}

.value {
  display: inline-block;
}

.multiple {
  padding-right: 30px;
}

.placeholder {
  padding: 5px 10px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: solid #ddd;
  border-width: 0 1px;
  border-radius: 10px;
  background: #fff;
  z-index: 999;
  height: 200px;
  overflow-y: auto;
}

.option {
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.option.selected {
  margin: -1px -1px 0;
}

.option.focused {
  background: #f5f5f5;
}

.checkbox {
  content: "";
  vertical-align: top;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 2px;
  border: 1px solid #ddd;
  border-radius: 2px;
  margin: 2px 12px 0 0;
  color: #fff;
  font-size: 10px;
}

.selected .checkbox {
  border-color: #e85936;
  background: #e85936;
}

.font-xxs {
  font-size: 10px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px !important;
  background: rgb(224, 224, 218);
  border-radius: 12px;
}

::-webkit-scroller {
  width: 6px;
  background: #000;
  border-radius: 100%;
}

::-webkit-scrollbar-thumb {
  background: #9a9aa6;
  width: 6px;

  border-radius: 100%;
  -webkit-border-radius: 20px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cards-container {
  overflow-x: scroll;
  position: relative;
}

@media screen and (max-width: 625px) {
  .container {
    width: 100%;
  }

  .cards-container {
    padding: 0.5rem;
  }

  .card {
    margin: 0.5rem;
  }
}

.css-jti3x1-MuiSlider-thumb {
  height: 12px !important;
  width: 12px !important;
}

.text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.table-header {
  position: sticky;
  top: 0;
}

.sticky-column {
  position: sticky;
  right: 0;
  background: white;
}

.sticky-column-header {
  right: 0;
  z-index: 5;
  display: block;
  background: white;
}

.filter-container {
  display: grid;
  grid-template-columns: 50% 50%;
}
.ag-row {
  border-bottom: none !important;
}
.ag-theme-material {
  --ag-foreground-color: rgb(126, 46, 132);
  --ag-background-color: rgb(249, 245, 227);
  --ag-header-foreground-color: rgb(204, 245, 172);
  --ag-header-background-color: rgb(209, 64, 129);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgb(126, 46, 132);
  --ag-alpine-accent-color: #e85936 !important;
  --ag-alpine-primary-color: #e85936 !important;
  --ag-checkbox-checked-color: #e85936 !important;
  --ag-row-hover-color: #e3e8fe94 !important;
  --ag-font-size: 17px;
  --ag-font-family: "Poppins" !important;
  --ag-alpine-active-color: #e85936 !important;
  --ag-material-primary-color: #e85936 !important;
}
.ag-sort-indicator-icon.ag-sort-descending-icon,
.ag-sort-indicator-icon.ag-sort-ascending-icon {
  color: #e85936;
}
.ag-sort-indicator-icon.ag-sort-order {
  color: #1a0a06;
}
.ag-header-icon {
  cursor: pointer;
}
/* .ag-cell-wrapper {
  margin-top: 20px !important;
} */
.ag-selection-checkbox {
  margin-top: -25px !important;
}
.cell-wrap-text {
  white-space: normal !important;
}

.ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
  border-left-color: #e85936 !important;
}
.ag-theme-material .ag-side-button-button:hover {
  color: #e85936 !important;
}
.ag-header-cell-label span.ag-icon.ag-icon-filter {
  color: #e85936;
}

/* to freeze the headers*/
/* thead>tr>td {
background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1;
} */
